import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
  <svg
    className={css.iconSvg}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.11635 0.609421C3.98403 0.609421 0.617874 3.97552 0.617874 8.10781C0.617874 12.2401 3.98403 15.6127 8.11635 15.6127C9.88139 15.6127 11.5047 14.9942 12.7876 13.9672L15.9111 17.0889C16.0687 17.24 16.2792 17.3234 16.4975 17.3211C16.7159 17.3189 16.9246 17.2313 17.0791 17.077C17.2336 16.9227 17.3216 16.7141 17.3241 16.4958C17.3267 16.2774 17.2436 16.0668 17.0927 15.909L13.9693 12.7856C14.9972 11.5007 15.6165 9.87486 15.6165 8.10781C15.6165 3.97552 12.2487 0.609421 8.11635 0.609421ZM8.11635 2.27611C11.3479 2.27611 13.9481 4.87628 13.9481 8.10781C13.9481 11.3393 11.3479 13.946 8.11635 13.946C4.88477 13.946 2.28456 11.3393 2.28456 8.10781C2.28456 4.87628 4.88477 2.27611 8.11635 2.27611Z"
      fill="#6F7C90"
    />
  </svg>
);

export default IconSearchDesktop;
